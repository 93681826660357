import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { format as formatDate } from 'date-fns'
import AnimateHeight from 'react-animate-height'
import { useIntl } from 'react-intl'

import Box from '../Box'
import More from '../More'
import bodyStyles from '../../styles/body'
import labelSmallStyles from '../../styles/labelSmall'
import I18nContext from '../../../plugins/gatsby-plugin-i18n/utils/I18nContext'

const Wrapper = styled.div`
  text-align: center;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Item = styled.li`
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    background: ${props => props.theme.colors.primary};
    width: 20%;
  }

  ${props =>
    props.isLast &&
    css`
      &:last-of-type:after {
        display: none;
      }
    `}
`

const StyledLink = styled(Link)`
  text-decoration: none;
  padding-bottom: 1rem;
  padding-top: 1rem;
  display: block;

  &:hover {
    h2 {
      font-weight: bold;
    }
  }
`

// TODO: Reuse e.g. with /Contact/Hours etc.
const Datetime = styled.div`
  ${labelSmallStyles}
  padding: 0.25rem 0 0.5rem 0;
`

const Title = styled.h2`
  ${bodyStyles}
  margin: 0;
  padding: 0;
`

const Label = styled.div`
  ${labelSmallStyles}
  padding-bottom: 0.2rem;
`

const Hidden = styled(AnimateHeight)`
  overflow: hidden;
`

const Previews = ({ news }) => {
  const [showMore, setShowMore] = useState(false)
  const featured = news.slice(0, 4)
  const more = news.slice(4, news.length)
  const intl = useIntl()
  const i18n = useContext(I18nContext)

  return (
    <Wrapper>
      <Box>
        <List>
          {featured.map(item => (
            <Item key={item.id} isLast={!showMore}>
              <StyledLink to={`/${i18n.currentLocale}/news/${item.slug}`}>
                <Datetime>
                  {formatDate(new Date(item.date), 'dd.MM.yyyy')}
                </Datetime>
                {item.label && <Label>{item.label}</Label>}
                <Title>{item.title}</Title>
              </StyledLink>
            </Item>
          ))}
        </List>
        <Hidden duration={500} height={showMore ? 'auto' : 0}>
          <List>
            {more.map(item => (
              <Item key={item.id} isLast>
                <StyledLink to={`/${i18n.currentLocale}/news/${item.slug}`}>
                  <Datetime>
                    {formatDate(new Date(item.date), 'dd.MM.yyyy')}
                  </Datetime>
                  {item.label && <Label>{item.label}</Label>}
                  <Title>{item.title}</Title>
                </StyledLink>
              </Item>
            ))}
          </List>
        </Hidden>
      </Box>
      {!showMore && more && more.length > 0 && (
        <More
          label={intl.formatMessage({ id: 'more' })}
          onClick={() => setShowMore(true)}
        />
      )}
    </Wrapper>
  )
}

Previews.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  news: PropTypes.array,
}

export default Previews
