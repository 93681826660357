import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { getYear } from 'date-fns'
import AnimateHeight from 'react-animate-height'
import { useIntl } from 'react-intl'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Content from '../components/Content'
import Title from '../components/Title'
import Spacer from '../components/Spacer'
import Previews from '../components/News/Previews'
import More from '../components/More'

const Hidden = styled(AnimateHeight)`
  overflow: hidden;
`

const Action = styled.div`
  margin: 0 0;
  padding-top: 1rem;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 168px;
    height: 1px;
    background: ${props => props.theme.colors.primary};
    transform: translateX(-50%);
  }
`

const NewsPage = ({ data }) => {
  const [showArchive, setShowArchive] = useState(false)
  const intl = useIntl()

  const news = data.allContentfulNews.edges.map(edge => {
    const { node } = edge
    return Object.assign(node)
  })

  // TODO: Combine reduce in above map. (Only loop once.)
  // TODO: Find a optimal structure like object but respecting order. Map()?
  // Structure: { 2019: [{}], 2018: [{}]}
  const newsByYear = news.reduce((accumulator, currentValue) => {
    const year = getYear(new Date(currentValue.date))
    if (!(year in accumulator)) {
      accumulator[year] = []
    }
    accumulator[year].push(currentValue)
    return accumulator
  }, {})

  // Structure: [{ year: '2019', news: [{...}]}, { year: '2018', news: [{...}]}]
  const newsByYearGrouped = Object.keys(newsByYear).map(key => {
    return {
      year: key,
      news: newsByYear[key],
    }
  })

  const newsByYearGroupedAndSorted = newsByYearGrouped.reverse()
  const featuredNews = newsByYearGroupedAndSorted.slice(0, 1)
  const archivedNews = newsByYearGroupedAndSorted.slice(1)

  return (
    <Layout>
      <SEO title="News" />
      <Content intro>
        {featuredNews.map(item => (
          <div key={item.year}>
            <Title top="News">{item.year}</Title>
            <Previews news={item.news} />

            <br />
            <br />
          </div>
        ))}
        <Hidden duration={500} height={showArchive ? 'auto' : 0}>
          {archivedNews.map(item => (
            <div key={item.year}>
              <Title top="News">{item.year}</Title>
              <Previews news={item.news} />
              <br />
              <br />
            </div>
          ))}
        </Hidden>
        {!showArchive && (
          <Action>
            <More
              label={intl.formatMessage({ id: 'archive' })}
              onClick={() => setShowArchive(true)}
            />
          </Action>
        )}
        <Spacer />
      </Content>
    </Layout>
  )
}

export default NewsPage

export const i18n = {
  en: '/news',
  de: '/news',
}

export const query = graphql`
  query GetNews($i18nCurrentLocale: String!) {
    allContentfulNews(
      filter: { node_locale: { eq: $i18nCurrentLocale } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          title
          label
          slug
          date
          content {
            json
          }
          download {
            title
            file {
              url
              contentType
            }
          }
        }
      }
    }
  }
`
